/* You can add HTML default tag styles */


@font-face {
  font-family : 'Nunito-Regular';
  src         : url('../fonts/nunito_regular.otf') format('truetype');
}
@font-face {
  font-family : 'Nunito-Bold';
  src         : url('../fonts/nunito_bold.otf') format('truetype');
}
@font-face {
  font-family : 'Arial-Amu-Regular';
  src         : url('../fonts/arial_amu_regular.otf');
}
@font-face {
  font-family : 'Arial-Amu-Bold';
  src         : url('../fonts/arial_amu_bold.otf');
}
@font-face {
  font-family : 'Montserratarm-Regular';
  src         : url('../fonts/Montserratarm-Regular.ttf');
}
@font-face {
  font-family : 'Montserratarm-Bold';
  src         : url('../fonts/Montserratarm-Bold.ttf');
}

* {
  margin      : 0;
  padding     : 0;
  color       : $black;
  box-sizing  : border-box;
  outline     : 0;
  box-sizing  : border-box;
  font-family : 'Nunito-Regular', 'Nunito-Bold', 'Arial-Amu-Regular', 'Arial-Amu-Bold','Lato', 'Segoe UI', Tahoma, 'Montserratarm-Regular', 'Montserratarm-Bold' , Geneva, Verdana, sans-serif;
  scrollbar-width: thin;
}


// html {
//   scroll-behavior: smooth;
// }

body {
  font-size  : 16px;
  color      : $black;
  min-height : 100vh;
  min-width  : 1024px;
  background : url('/assets/images/background.png') no-repeat center/cover;

  a {
    color : $black;

    &:hover {
      color: $black;
    }
  }
}

a {
  text-decoration : none;
  color           : aliceblue;
}

textarea, input, button, pre {
  font-size   : 16px !important;
  outline     : none !important;
}

input {
  height           : $input-height;
  border           : 1px solid $light-gray;
  text-indent      : 10px;
  background-color : transparent;
  color            : $black;
}

textarea {
  width       : 100%;
  height      : calc(#{$input-height} + 10px);
  max-height  : 400px;
  min-height  : calc(#{$input-height} + 40px);
  padding     : 10px;
  text-indent : 0;
  resize      : vertical;
}

pre {
  white-space : pre-wrap;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  margin     : 0;
  appearance : none;
}

input[type=file] {
  display : none;
}

i[class*=icon] {
  cursor : pointer;
}

textarea { resize : none; }
a, button, label { cursor : pointer; }

button {
  border           : 0;
  background-color : transparent;
}

h1, h2, h3, h4, h5, h6 {
  letter-spacing : 1px;
}

h1 { font-size : 40px; }
h2 { font-size : 32px; }
h3 { font-size : 28px; }
h4 { font-size : 24px; }
h5 { font-size : 22px; }
h6 { font-size : 18px; }
a, p { font-size : 16px; }

@media (max-width: 1400px) {
  h1 { font-size : 30px; }
  h2 { font-size : 24px; }
  h3 { font-size : 24px; }
  h4 { font-size : 20px; }
  h5 { font-size : 18px; }
  h6 { font-size : 18px; }
}

*::-webkit-scrollbar-track, *::-ms-autohiding-scrollbar {
  border-radius    :  2px;
  background-color :  $light;
}

*::-webkit-scrollbar {
  width            : 4px;
  height           : 8px;
  background-color : $light;
}

*::-webkit-scrollbar-thumb {
  border-radius      : 4px;
  background-color   : grey;
  
  scrollbar-width: thin !important;
}

input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
