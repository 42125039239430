/* Inputtext */


// float label
.G-float-input-wrap {
    height: 60px;
    border: 1px solid $gray;
    position: relative;
    display: flex;
    padding-right: 30px;

    .G-float-input {
        width: 100%;
        height: 33px;
        margin-top: 20px;
        border: none;
    }

    .G-label {
        position: absolute;
        left: 10px;
        top: 10px;
        font-size: 12px;
        color: $gray;
        transition: all 0.3s;
    }
    
    .G-label-empty {
        top: 50%;
        transform: translateY(-50%);
        font-size: 16px;
    }
}

// end float label
