@import 'variables';

.G-border-radius-1 { border-radius : 1px; }
.G-border-radius-2 { border-radius : 2px; }
.G-border-radius-3 { border-radius : 3px; }
.G-border-radius-4 { border-radius : 4px; }
.G-border-radius-5 { border-radius : 5px; }
.G-border-radius-6 { border-radius : 6px; }
.G-border-radius-7 { border-radius : 7px; }
.G-border-radius-8 { border-radius : 8px; }
.G-border-radius-9 { border-radius : 9px; }
.G-border-radius-10 { border-radius : 10px; }
.G-border-radius-50 { border-radius : 50px; }
.G-border-radius-50p { border-radius : 50%; }

.G-border-top-left-radius-1 { border-radius : 1px; }
.G-border-top-left-radius-2 { border-top-left-radius : 2px; }
.G-border-top-left-radius-3 { border-top-left-radius : 3px; }
.G-border-top-left-radius-4 { border-top-left-radius : 4px; }
.G-border-top-left-radius-5 { border-top-left-radius : 5px; }
.G-border-top-left-radius-6 { border-top-left-radius : 6px; }
.G-border-top-left-radius-7 { border-top-left-radius : 7px; }
.G-border-top-left-radius-8 { border-top-left-radius : 8px; }
.G-border-top-left-radius-9 { border-top-left-radius : 9px; }
.G-border-top-left-radius-10 { border-top-left-radius : 10px; }

.G-border-top-right-radius-1 { border-top-right-radius : 1px; }
.G-border-top-right-radius-2 { border-top-right-radius : 2px; }
.G-border-top-right-radius-3 { border-top-right-radius : 3px; }
.G-border-top-right-radius-4 { border-top-right-radius : 4px; }
.G-border-top-right-radius-5 { border-top-right-radius : 5px; }
.G-border-top-right-radius-6 { border-top-right-radius : 6px; }
.G-border-top-right-radius-7 { border-top-right-radius : 7px; }
.G-border-top-right-radius-8 { border-top-right-radius : 8px; }
.G-border-top-right-radius-9 { border-top-right-radius : 9px; }
.G-border-top-right-radius-10 { border-top-right-radius : 10px; }

.G-border-bottom-left-radius-1 { border-bottom-left-radius : 1px; }
.G-border-bottom-left-radius-2 { border-bottom-left-radius : 2px; }
.G-border-bottom-left-radius-3 { border-bottom-left-radius : 3px; }
.G-border-bottom-left-radius-4 { border-bottom-left-radius : 4px; }
.G-border-bottom-left-radius-5 { border-bottom-left-radius : 5px; }
.G-border-bottom-left-radius-6 { border-bottom-left-radius : 6px; }
.G-border-bottom-left-radius-7 { border-bottom-left-radius : 7px; }
.G-border-bottom-left-radius-8 { border-bottom-left-radius : 8px; }
.G-border-bottom-left-radius-9 { border-bottom-left-radius : 9px; }
.G-border-bottom-left-radius-10 { border-bottom-left-radius : 10px; }


.G-border-bottom-right-radius-1 { border-bottom-right-radius : 1px; }
.G-border-bottom-right-radius-2 { border-bottom-right-radius : 2px; }
.G-border-bottom-right-radius-3 { border-bottom-right-radius : 3px; }
.G-border-bottom-right-radius-4 { border-bottom-right-radius : 4px; }
.G-border-bottom-right-radius-5 { border-bottom-right-radius : 5px; }
.G-border-bottom-right-radius-6 { border-bottom-right-radius : 6px; }
.G-border-bottom-right-radius-7 { border-bottom-right-radius : 7px; }
.G-border-bottom-right-radius-8 { border-bottom-right-radius : 8px; }
.G-border-bottom-right-radius-9 { border-bottom-right-radius : 9px; }
.G-border-bottom-right-radius-10 { border-bottom-right-radius : 10px; }

.G-border-top-x-radius-1 { border-top-right-radius : 1px; border-top-left-radius : 1px; }
.G-border-top-x-radius-2 { border-top-right-radius : 2px; border-top-left-radius : 2px; }
.G-border-top-x-radius-3 { border-top-right-radius : 3px; border-top-left-radius : 3px; }
.G-border-top-x-radius-4 { border-top-right-radius : 4px; border-top-left-radius : 4px; }
.G-border-top-x-radius-5 { border-top-right-radius : 5px; border-top-left-radius : 5px; }
.G-border-top-x-radius-6 { border-top-right-radius : 6px; border-top-left-radius : 6px; }
.G-border-top-x-radius-7 { border-top-right-radius : 7px; border-top-left-radius : 7px; }
.G-border-top-x-radius-8 { border-top-right-radius : 8px; border-top-left-radius : 8px; }
.G-border-top-x-radius-9 { border-top-right-radius : 9px; border-top-left-radius : 9px; }
.G-border-top-x-radius-10 { border-top-right-radius : 10px; border-top-left-radius : 10px; }

.G-border-bottom-x-radius-1 { border-bottom-right-radius : 1px; border-bottom-left-radius : 1px; }
.G-border-bottom-x-radius-2 { border-bottom-right-radius : 2px; border-bottom-left-radius : 2px; }
.G-border-bottom-x-radius-3 { border-bottom-right-radius : 3px; border-bottom-left-radius : 3px; }
.G-border-bottom-x-radius-4 { border-bottom-right-radius : 4px; border-bottom-left-radius : 4px; }
.G-border-bottom-x-radius-5 { border-bottom-right-radius : 5px; border-bottom-left-radius : 5px; }
.G-border-bottom-x-radius-6 { border-bottom-right-radius : 6px; border-bottom-left-radius : 6px; }
.G-border-bottom-x-radius-7 { border-bottom-right-radius : 7px; border-bottom-left-radius : 7px; }
.G-border-bottom-x-radius-8 { border-bottom-right-radius : 8px; border-bottom-left-radius : 8px; }
.G-border-bottom-x-radius-9 { border-bottom-right-radius : 9px; border-bottom-left-radius : 9px; }
.G-border-bottom-x-radius-10 { border-bottom-right-radius : 10px; border-bottom-left-radius : 10px; }
