.G-fw-unset { font-weight: unset !important; }
.G-fs-unset { font-size: unset !important; }
.G-width-unset { width : unset !important; }
.G-min-width-unset { min-width : unset !important; }
.G-max-width-unset { max-width : unset !important; }
.G-height-unset { height : unset !important; }
.G-min-height-unset { min-height : unset !important; }
.G-max-height-unset { max-height : unset !important; }
.G-m-unset { margin : unset !important; }
.G-mx-unset { margin-left : unset !important; margin-right : unset !important; }
.G-my-unset { margin-top : unset !important; margin-bottom : unset !important; }
.G-mt-unset { margin-top : unset !important; }
.G-mb-unset { margin-bottom : unset !important; }
.G-mr-unset { margin-right : unset !important; }
.G-ml-unset { margin-left : unset !important; }
.G-p-unset { padding : unset !important; }
.G-px-unset { padding-left : unset !important; padding-right : unset !important; }
.G-py-unset { padding-top : unset !important; padding-bottom : unset !important; }
.G-pt-unset { padding-top : unset !important; }
.G-pb-unset { padding-bottom : unset !important; }
.G-pr-unset { padding-right : unset !important; }
.G-pl-unset { padding-left : unset !important; }
.G-overflow-unset { overflow : unset !important; }
.G-position-unset { position : unset !important; }
.G-z-index-unset { z-index : unset !important; }
.G-disable-unset {
    user-select    : none !important;
    pointer-events : none !important;
  }

