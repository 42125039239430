
.G-p-05 { padding: 5px; }
.G-p-1 { padding: 10px; }
.G-p-2 { padding: 20px; }
.G-p-3 { padding: 30px; }
.G-p-4 { padding: 40px; }
.G-p-5 { padding: 50px; }
.G-p-6 { padding: 60px; }

.G-pb-05 { padding-bottom: 5px; }
.G-pb-1 { padding-bottom: 10px; }
.G-pb-2 { padding-bottom: 20px; }
.G-pb-3 { padding-bottom: 30px; }
.G-pb-4 { padding-bottom: 40px; }
.G-pb-5 { padding-bottom: 50px; }
.G-pb-6 { padding-bottom: 60px; }

.G-pt-05 { padding-top: 5px; }
.G-pt-1 { padding-top: 10px; }
.G-pt-2 { padding-top: 20px; }
.G-pt-3 { padding-top: 30px; }
.G-pt-4 { padding-top: 40px; }
.G-pt-5 { padding-top: 50px; }
.G-pt-6 { padding-top: 60px; }

.G-pr-05 { padding-right: 5px; }
.G-pr-1 { padding-right: 10px; }
.G-pr-2 { padding-right: 20px; }
.G-pr-3 { padding-right: 30px; }
.G-pr-4 { padding-right: 40px; }
.G-pr-5 { padding-right: 50px; }
.G-pr-6 { padding-right: 60px; }

.G-pl-05 { padding-left: 5px; }
.G-pl-1 { padding-left: 10px; }
.G-pl-2 { padding-left: 20px; }
.G-pl-3 { padding-left: 30px; }
.G-pl-4 { padding-left: 40px; }
.G-pl-5 { padding-left: 50px; }
.G-pl-6 { padding-left: 60px; }

.G-px-05 {
  padding-left  : 5px;
  padding-right : 5px;
}
.G-px-1 {
  padding-left  : 10px;
  padding-right : 10px;
}
.G-px-2 {
  padding-left  : 20px;
  padding-right : 20px;
}
.G-px-3 {
  padding-left  : 30px;
  padding-right : 30px;
}
.G-px-4 {
  padding-left  : 40px;
  padding-right : 40px;
}
.G-px-5 {
  padding-left  : 50px;
  padding-right : 50px;
}
.G-px-6 {
  padding-left  : 60px;
  padding-right : 60px;
}

.G-py-05 {
  padding-top    : 5px;
  padding-bottom : 5px;
}
.G-py-1 {
  padding-top    : 10px;
  padding-bottom : 10px;
}
.G-py-2 {
  padding-top    : 20px;
  padding-bottom : 20px;
}
.G-py-3 {
  padding-top    : 30px;
  padding-bottom : 30px;
}
.G-py-4 {
  padding-top    : 40px;
  padding-bottom : 40px;
}
.G-py-5 {
  padding-top    : 50px;
  padding-bottom : 50px;
}
.G-py-6 {
  padding-top    : 60px;
  padding-bottom : 60px;
}
