@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?7fqux3');
  src:  url('fonts/icomoon.eot?7fqux3#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?7fqux3') format('truetype'),
    url('fonts/icomoon.woff?7fqux3') format('woff'),
    url('fonts/icomoon.svg?7fqux3#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-ic_arrowdown:before {
  content: "\e900";
}
.icon-ic_arrowleft:before {
  content: "\e901";
}
.icon-ic_arrowright:before {
  content: "\e902";
}
.icon-ic_arrowup:before {
  content: "\e903";
}
.icon-ic_back:before {
  content: "\e904";
}
.icon-ic_close:before {
  content: "\e905";
}
.icon-ic_dashboard:before {
  content: "\e906";
}
.icon-ic_eye:before {
  content: "\e907";
}
.icon-ic_eyeclosed:before {
  content: "\e908";
}
.icon-ic_loyality-:before {
  content: "\e909";
}
.icon-ic_loyality:before {
  content: "\e90a";
}
.icon-ic_more:before {
  content: "\e90b";
}
.icon-ic_notifications:before {
  content: "\e90c";
}
.icon-ic_plus:before {
  content: "\e90d";
}
.icon-ic_transactions:before {
  content: "\e90e";
}
.icon-ic_user:before {
  content: "\e90f";
}
.icon-ic_support:before {
  content: "\e910";
}
.icon-ic_settings:before {
  content: "\e911";
}
.icon-ic_calendar:before {
  content: "\e912";
}
.icon-ic_important:before {
  content: "\e913";
}
.icon-ic_mail:before {
  content: "\e914";
}
.icon-ic_more1:before {
  content: "\e915";
}
.icon-ic_phone:before {
  content: "\e916";
}
.icon-ic_car:before {
  content: "\e917";
}
.icon-icon-12:before {
  content: "\e918";
}
.icon-id-card:before {
  content: "\e919";
}
.icon-ic_tax:before {
  content: "\e91a";
}
.icon-ic_map:before {
  content: "\e91b";
}
.icon-ic_list:before {
  content: "\e91c";
}
.icon-ic_comment:before {
  content: "\e91d";
}
.icon-clock-5:before {
  content: "\e91e";
}
.icon-cancel:before {
  content: "\e91f";
}
.icon-ic_increase:before {
  content: "\e920";
}
.icon-ic_decrease:before {
  content: "\e921";
}
.icon-ic_star:before {
  content: "\e922";
}
