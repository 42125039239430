@import "./globals/size";
@import "./globals/flex";
@import "./globals/margin";
@import "./globals/padding";
@import "./globals/color";
@import "./globals/button";
@import "./globals/input";
@import "./globals/block";
@import "./globals/text";
@import "./globals/unset";
@import "./globals/border";
@import "./variables";
@import "./normalize";
@import "../icons/style";
@import "./globals/notification.scss";
@import "./globals/theme.css";
@import url("https://fonts.googleapis.com/css?family=PT+Sans:400,700&display=swap");

.G-main-page {
  margin-top: 12vh;
  padding: 0px 60px;
  padding-bottom: 20px;
  width: 100%;
  min-height: 93vh;
  max-width: 1700px;
  background-color: #f6f7f9;
  margin-left: 320px;
  transition: 0.5s;
  zoom: 0.95;
}

.P-small {

  .G-main-page {
    transition: 0.5s;
    margin-left: 100px !important;
    max-width: 1900px;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

/* Firefox */
input[type=number] {
  -moz-appearance:textfield !important;
}

@media (max-width: 1600px) {

  .P-sidebar {
    width: 260px !important;
  }
  .P-sidebar .P-sidebar-close {
    .P-sidebar-header {
      width: 100px !important;
    }
  }
  .P-sidebar .P-sidebar-content {
    width: 260px !important;
    .P-sidebar-item {
      font-size: 14px !important;
      padding-left: 50px !important;
    }
    .P-sidebar-nested-item {
      padding-left: 80px !important;
    }
  }
  .G-main-page:not(.P-login-page) {
    margin-left: 260px;
    padding: 35px 50px;
  }
  .HQ-data-table > .HQ-data-table-header > li {
    font-size: 11px !important;
    margin-right: 1% !important;
  }
  .HQ-data-table > .HQ-data-table-body > ul > li {
    font-size: 13px !important;
    margin-right: 1% !important;
  }
}

@media (max-width: 1500px) {
  .HQ-data-table-header {
    padding: 15px 30px !important;
  }
  .HQ-data-table > .HQ-data-table-body > ul,
  .HQ-data-table > .HQ-data-table-body > a {
    padding: 15px 30px !important;
  }
  .P-customers-page,
  .P-orders-page {
    padding: 35px 30px !important;
  }
  .P-content .P-sidebar-close .P-sidebar-content {
    width: 100px !important;
  }
  .P-sidebar-close {
    width: 100px !important;
  }
  .P-sidebar {
    width: 290px !important;
  }
  .P-sidebar .P-sidebar-content {
    width: 290px !important;
  }
  .P-sidebar .P-sidebar-item {
    padding-left: 50px !important;
  }
  .P-sidebar .P-sidebar-nested-item {
    padding-left: 80px !important;
  }
  .G-main-page:not(.P-login-page) {
    margin-left: 290px !important;
    padding: 35px 20px !important;
  }
  .P-small {
    .G-main-page:not(.P-login-page) {
      margin-left: 100px !important;
      padding: 35px 20px !important;
    }
  }
  .P-sellers-orders-page .P-image {
    margin-right: 10px !important;
  }

  .P-sidebar .P-sidebar-content {
    width: 260px !important;

    .P-sidebar .P-sidebar-item {
      font-size: 14px !important;
      padding-left: 50px !important;
    }
  }
}

@media (max-width: 1450px) {
  .P-customers-page,
  .P-orders-page {
    padding: 35px 10px 35px 30px !important;
  }
 
  .P-sellers-orders-page .P-image {
    margin-right: 10px !important;
  }
}

@media (max-width: 1400px) {
  .HQ-data-table-header {
    padding: 15px 20px !important;
  }
  .HQ-data-table > .HQ-data-table-body > ul,
  .HQ-data-table > .HQ-data-table-body > a {
    padding: 15px 20px !important;
  }
  .P-sellers-orders-page .P-image {
    margin-right: 10px !important;
  }
}

@media (max-width: 1350px) {

  .HQ-data-table-header {
    padding: 15px 10px !important;
  }
  .HQ-data-table > .HQ-data-table-body > ul,
  .HQ-data-table > .HQ-data-table-body > a {
    padding: 0 5px !important;
  }
  .HQ-data-table > .HQ-data-table-header > li {
    font-size: 9px !important;
    margin-right: 1% !important;
  }
  .HQ-data-table > .HQ-data-table-body > ul > li {
    font-size: 11px !important;
    margin-right: 1% !important;
  }
  .P-customers-page,
  .P-orders-page {
    margin-left: 240px !important;
    padding: 45px 10px !important;
  }
  .HQ-data-table{
    overflow: auto;
    .P-image {
      width: 30px !important;
      min-width: 30px !important;
      height: 30px !important;
      margin-right: 10px !important;
    }
  }

  .G-main-page:not(.P-login-page) {
    margin-left: 250px !important;
  }

  .P-customers-page,
  .P-orders-page {
    padding: 35px 20px !important;
  }
  .P-sidebar {
    width: 240px !important;
  }
  .P-sidebar .P-sidebar-content {
    width: 240px !important;
  }
  .P-sidebar .P-sidebar-item {
    padding-left: 50px !important;
    font-size: 13px !important;
    min-height: 55px;
  }
  .P-sidebar .P-sidebar-nested-item {
    padding-left: 80px !important;
  }
}

.G-display-none {
  display: none;
}

.G-logo {
  // margin-top: -20px;
  height: 10vh;
  width: 10vw;
}

.G-logo-small {
  margin-top: 0;
  height: 8vh;
  width: 8vw;
}

.G-text-input {
  // width: 227px;
  width: 220px;
  height: 45px;
  border: 1px solid $light-gray;
  border-radius: 9px;
  background-color: white;
  padding-left: 10px;
  ::placeholder {
    color: $dark-gray;
  }
  ::-webkit-input-placeholder {
    color: $dark-gray;
  }
}

.G-textarea {
  // width: 227px;
  width: 220px;
  min-height: 100px;
  border: 1px solid $light-gray;
  border-radius: 9px;
  background-color: white;
  padding-left: 10px;
  ::placeholder {
    color: $dark-gray;
  }
  ::-webkit-input-placeholder {
    color: $dark-gray;
  }
}

.G-button {
  color: $white;
  background-color: $green;
  border-radius: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  cursor: pointer;
}

.nowrap {
  white-space: nowrap;
}

.G-arrow-animation {
  transform: rotate(180deg);
}

.G-capitalize {
  text-transform: capitalize;
}

.G-main-color {
  color: $main-color;
}

.G-green-color {
  color: $green;
}

.G-bold {
  font-weight: bold;
}

.G-bg-image {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.G-circle-image {
  width: 35px;
  min-width: 35px;
  height: 35px;
  border-radius: 50%;
}

.G-half {
  width: calc(50% - 20px) !important;
}

.G-text-center {
  text-align: center;
}

.G-cursor {
  cursor: pointer;
}

// #region Font Size

$fonts: (10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 24, 28);

@each $size in $fonts {
  .G-fs-#{$size} {
    font-size: #{$size + px};
  }
}

// #endregion

// #region Flex

.G-flex {
  display: flex;
}

.G-center {
  justify-content: center;
  align-items: center;
}

.G-justify-center {
  justify-content: center;
}

.G-justify-between {
  justify-content: space-between;
}

.G-justify-around {
  justify-content: space-around;
}

.G-justify-end {
  justify-content: flex-end;
}

.G-align-center {
  align-items: center;
}

.G-align-baseline {
  align-items: baseline;
}

.G-align-start {
  align-items: flex-start;
}

.G-align-end {
  align-items: flex-end;
}

.G-flex-column {
  flex-direction: column;
}

.G-flex-wrap {
  flex-wrap: wrap;
}

// #endregion

// #region Buttons

.G-btn {
  padding: 0 30px;
  height: $input-height;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  color: $white;
  background: $app-color;

  * {
    color: $white;
  }

  &.G-small {
    font-size: 14px !important;
    padding: 0 20px;
    height: 30px;
    border-radius: 10px;
  }

  &.G-smallest {
    font-size: 11px !important;
    padding: 0 5px;
    height: 25px;
  }

  &.G-black-btn {
    background: $black;
  }

  &.G-gray-btn {
    background: $light-gray;
  }

  &.G-green-btn {
    background: $green;
  }

  &.G-white-btn {
    background: $white;

    * {
      color: $black;
    }
  }

  &.G-empty {
    border: 1px solid $main-color;
    color: $main-color;
    background: $white;

    * {
      color: $main-color;
    }
  }
}

.G-error {
  border: 1px solid red !important;
}

.G-error-shadow {
  box-shadow: 0 0 10px transparentize($red, 0.1) !important;
}
.G-main-container {
  margin-top: 140px;
}

// #region Sections

.G-block {
  width: 100%;
  padding: 20px;
  border-radius: $radius;
  box-shadow: $shadow;
  background-color: $white;
}

.G-block-title {
  margin-bottom: 30px;
  font-size: 16px;
  font-weight: bold;
}

// #endregion

// #region Checkbox directive

.G-checkbox-item {
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  font-size: 10px;
  font-weight: bold;
  border-radius: 3px;
  border: 1px solid #bbbbbb;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: $white;
}

.G-checkbox-active {
  border: 2px solid $main-color;
  color: $white;
  background-color: $main-color;
}

// #endregion

// #region Radio directive

.G-radio-item {
  width: 17px;
  height: 17px;
  min-width: 17px;
  min-height: 17px;
  border-radius: 50%;
  border: 1px solid $red-dark;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;

  &.G-radio-active {
    &::before {
      content: "";
      width: calc(100% - 6px);
      height: calc(100% - 6px);
      border-radius: 50%;
      position: absolute;
      top: 3px;
      left: 3px;
      background-color: $red-dark;
    }
  }
}

// #endregion

// #region Page

.G-page {
  min-height: 100vh;
  padding: calc(#{$header-height} + 40px) 60px 40px;
  position: relative;
}

.G-container {
  width: 100%;
  padding: 0 $padding;
}

.G-cover {
  padding-top: $header-height;
}

.G-position-relative {
  position: relative;
}

// #endregion

// #region Margins

.G-mb-1 {
  margin-bottom: 10px;
}

.G-mb-2 {
  margin-bottom: 20px;
}

.G-mb-3 {
  margin-bottom: 30px;
}

.G-mb-4 {
  margin-bottom: 40px;
}

.G-mb-5 {
  margin-bottom: 50px;
}

.G-mb-6 {
  margin-bottom: 60px;
}

.G-mt-1 {
  margin-top: 10px;
}

.G-mt-2 {
  margin-top: 20px;
}

.G-mt-3 {
  margin-top: 30px;
}

.G-mt-4 {
  margin-top: 40px;
}

.G-mt-5 {
  margin-top: 50px;
}

.G-mt-6 {
  margin-top: 60px;
}

.G-mr-1 {
  margin-right: 10px;
}

.G-mr-2 {
  margin-right: 20px;
}

.G-mr-3 {
  margin-right: 30px;
}

.G-mr-4 {
  margin-right: 40px;
}

.G-mr-5 {
  margin-right: 50px;
}

.G-mr-6 {
  margin-right: 60px;
}

.G-ml-1 {
  margin-left: 10px;
}

.G-ml-2 {
  margin-left: 20px;
}

.G-ml-3 {
  margin-left: 30px;
}

.G-ml-4 {
  margin-left: 40px;
}

.G-ml-5 {
  margin-left: 50px;
}

.G-ml-6 {
  margin-left: 60px;
}

// #endregion
