@import '../../../../assets/styles/variables.scss';

.P-managment-faq {
  button {
    min-width     : 180px;
    margin-left   : auto;
    margin-bottom : 20px;
    margin-top    : -60px;
  }

  .G-block {
    padding: 20px;
    display: flex;

    .P-question {
      font-weight: bold;
      margin-bottom: 20px;
    }

    .HQ-data-table {
      box-shadow: none;

      .HQ-data-table-header {
        box-shadow: none;
      }
    }

    .P-title {
      padding: 10px 20px;
      display: flex;
      justify-content: flex-end;
    }

    .P-toolbar-wrapper {
      margin-top: 0;
    }
  }
}