
.G-m-05 { margin: 5px; }
.G-m-1 { margin: 10px; }
.G-m-2 { margin: 20px; }
.G-m-3 { margin: 30px; }
.G-m-4 { margin: 40px; }
.G-m-5 { margin: 50px; }
.G-m-6 { margin: 60px; }

.G-mb-02 { margin-bottom: 2px; }
.G-mb-05 { margin-bottom: 5px; }
.G-mb-1 { margin-bottom: 10px; }
.G-mb-2 { margin-bottom: 20px; }
.G-mb-3 { margin-bottom: 30px; }
.G-mb-4 { margin-bottom: 40px; }
.G-mb-5 { margin-bottom: 50px; }
.G-mb-6 { margin-bottom: 60px; }
.G-mb-1- { margin-bottom: -10px; }

.G-mt-02- { margin-top: -2px; }
.G-mt-05- { margin-top: -5px; }
.G-mt-05 { margin-top: 5px; }
.G-mt-1- { margin-top: -10px; }
.G-mt-1 { margin-top: 10px; }
.G-mt-15 { margin-top: 15px; }
.G-mt-2 { margin-top: 20px; }
.G-mt-3 { margin-top: 30px; }
.G-mt-4 { margin-top: 40px; }
.G-mt-5 { margin-top: 50px; }
.G-mt-6 { margin-top: 60px; }
.G-mt-25- { margin-top: -25px; }

.G-mr-05 { margin-right: 5px }
.G-mr-1 { margin-right: 10px; }
.G-mr-2 { margin-right: 20px; }
.G-mr-3 { margin-right: 30px; }
.G-mr-4 { margin-right: 40px; }
.G-mr-5 { margin-right: 50px; }
.G-mr-6 { margin-right: 60px; }

.G-ml-05 { margin-left: 5px; }
.G-ml-1 { margin-left: 10px; }
.G-ml-15 { margin-left: 15px; }
.G-ml-2- { margin-left: -20px; }
.G-ml-2 { margin-left: 20px; }
.G-ml-3 { margin-left: 30px; }
.G-ml-4 { margin-left: 40px; }
.G-ml-5 { margin-left: 50px; }
.G-ml-6 { margin-left: 60px; }

.G-mr-0 {
  margin-right: 0;
}

.G-ml-0 {
  margin-left: 0;
}

.G-mt-0 {
  margin-top: 0;
}

.G-mb-0 {
  margin-bottom: 0;
}

.G-pr-0 {
  padding-right: 0;
}

.G-pl-0 {
  padding-left: 0;
}

.G-pt-0 {
  padding-top: 0;
}

.G-pb-0 {
  padding-bottom: 0;
}

.G-mx-05 {
  margin-left  : 5px;
  margin-right : 5px;
}
.G-mx-1 {
  margin-left  : 10px;
  margin-right : 10px;
}
.G-mx-2 {
  margin-left  : 20px;
  margin-right : 20px;
}
.G-mx-3 {
  margin-left  : 30px;
  margin-right : 30px;
}
.G-mx-4 {
  margin-left  : 40px;
  margin-right : 40px;
}
.G-mx-5 {
  margin-left  : 50px;
  margin-right : 50px;
}
.G-mx-6 {
  margin-left  : 60px;
  margin-right : 60px;
}

.G-my-05 {
  margin-top    : 5px;
  margin-bottom : 5px;
}
.G-my-1 {
  margin-top    : 10px;
  margin-bottom : 10px;
}
.G-my-2 {
  margin-top    : 20px;
  margin-bottom : 20px;
}
.G-my-3 {
  margin-top    : 30px;
  margin-bottom : 30px;
}
.G-my-4 {
  margin-top    : 40px;
  margin-bottom : 40px;
}
.G-my-5 {
  margin-top    : 50px;
  margin-bottom : 50px;
}
.G-my-6 {
  margin-top    : 60px;
  margin-bottom : 60px;
}


.G-ml-auto { margin-left : auto; }
.G-mr-auto { margin-right: auto; }
.G-mt-auto { margin-top : auto; }
.G-mb-auto { margin-bottom: auto; }
