.G-block {
  width            : 100%;
  padding          : 20px;
  border-radius    : $radius;
  box-shadow       : $shadow;
  background-color : $white;
}

.G-block-title {
  margin-bottom : 30px;
  font-size     : 16px;
  font-weight   : bold;
}

.G-card {
    box-shadow: $shadow;
    width: 100%;
    height: 100%;
  
    &> div {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
}

.G-overflow-auto { overflow : auto; }
